<template>
  <div v-if="!rail" class="px-2 pb-2" style="font-size: 11px">
    <v-divider class="my-2" />
    <div><v-icon icon="call" /> <a style="color: white" href="tel:+31850601404">085-0601404</a></div>
    <div><v-icon icon="mail" /> <a style="color: white" href="mailto:support@sambeheer.nl">support@sambeheer.nl</a></div>
    <div class="mt-2"><v-icon icon="copyright" /> SAM B.V. {{ new Date().getFullYear() }}</div>
    <div :title="$d(new Date(runtimeConfig.public.JOB_TIMESTAMP), 'long')">v{{ frontendVersion }} FRONT</div>
    <div v-if="backendVersion && backendDate" :title="$d(new Date(backendDate), 'long')">v{{ backendVersion }} API</div>
  </div>
</template>

<script setup lang="ts">
import dayjs from "dayjs"

interface VersionInfo {
  version: string
  tier: string
  versionDate: string
  buildDate: string
}

defineProps<{ rail?: boolean }>()

const runtimeConfig = useRuntimeConfig()

const backendVersion = ref("")
const backendDate = ref("")
const frontendVersion = computed(() => {
  const { COMMIT_TIMESTAMP, COMMIT_SHA, ENVIRONMENT_TIER } = runtimeConfig.public
  const date = COMMIT_TIMESTAMP ? dayjs(COMMIT_TIMESTAMP) : dayjs()

  const versionString = `${date.format("YY.M")}.${COMMIT_SHA}`

  return ENVIRONMENT_TIER === "production" ? versionString : `${versionString}-${ENVIRONMENT_TIER}`
})

onMounted(async () => {
  try {
    const { data: backendVersionData } = await useSamApi<VersionInfo>("/version")
    backendVersion.value =
      backendVersionData.tier === "production" ? backendVersionData.version : `${backendVersionData.version}-${backendVersionData.tier}`
    backendDate.value = backendVersionData.buildDate
  } catch (error) {
    console.error("Failed to fetch backend version", error)
  }
})
</script>

<template>
  <v-navigation-drawer v-model="opened" disable-resize-watcher class="map-drawer" location="right" elevation="4" :width="rightDrawerWidth">
    <google-map v-if="opened && useGoogleMaps" class="w-100 h-100" v-bind="$attrs" />
    <open-street-map v-else-if="opened" class="w-100 h-100" v-bind="$attrs" />

    <v-btn
      v-if="$route.meta.map"
      class="map-drawer-button"
      dark
      :icon="opened ? 'chevron_right' : 'map'"
      :style="{ left: '-20px' }"
      style="font-size: 10px"
      @click="() => (opened = !opened)"
    />

    <div
      v-if="$vuetify.display.lgAndUp"
      ref="resizeHandle"
      class="position-absolute"
      style="width: 8px; top: 0; bottom: 0; left: -4px; z-index: 1; cursor: col-resize"
    />
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { useDisplay, useLayout } from "vuetify"

defineOptions({ inheritAttrs: false })

const props = defineProps<{ maxPageWidth?: number }>()
const { maxPageWidth } = toRefs(props)

const { mainRect } = useLayout()
const { width: screenWidth, lgAndUp } = useDisplay()
const $route = useRoute()

const { mapVisibility, useGoogleMaps } = useUserPreferences()

const resizeHandle = ref<HTMLElement | null>(null)
const opened = ref(false)

// const { isDragging, x } = useDraggable(resizeHandle, { axis: "x" })

//   if (isDragging && x > 0) {
//     const relativeXFromRight = screenWidth.value - x
//     rightDrawerWidth.value = Math.max(DEFAULT_WIDTH, Math.min(maxWidth.value, relativeXFromRight))
//   }
// }, { debounce: 10 })

watch(
  () => [$route.meta.map as MapVisibility, lgAndUp.value] as const,
  (value) => {
    const [mapKey] = value

    if (!mapKey) {
      opened.value = false
    }
    if (mapKey && mapVisibility.value.includes(mapKey) && lgAndUp.value) {
      opened.value = true
    }
  },
  { immediate: true },
)

watch(opened, () => {
  if ($route.meta.map) {
    if (!opened.value && mapVisibility.value.includes($route.meta.map as MapVisibility)) {
      mapVisibility.value = mapVisibility.value.filter((visibility: MapVisibility) => visibility !== $route.meta.map)
    } else if (opened.value && !mapVisibility.value.includes($route.meta.map as MapVisibility)) {
      mapVisibility.value.push($route.meta.map as MapVisibility)
    }
  }
})

const DEFAULT_WIDTH = 340
// const DEFAULT_MAX_WIDTH = 800
// const defaultMaxWidth = computed(() => Math.max(340, Math.min(DEFAULT_MAX_WIDTH, screenWidth.value / 2)))
const rightDrawerWidth = ref(DEFAULT_WIDTH)

const maxWidth = computed(() => {
  if (!maxPageWidth?.value) {
    return DEFAULT_WIDTH
  }
  // if (!maxPageWidth?.value) { return defaultMaxWidth.value}
  if (mainRect.value === undefined) {
    return DEFAULT_WIDTH
  }
  if (!opened.value) {
    return DEFAULT_WIDTH
  }

  const mainContentWidth = screenWidth.value - mainRect.value.left

  if (mainContentWidth > maxPageWidth.value + DEFAULT_WIDTH) {
    return mainContentWidth - maxPageWidth.value
  }

  return DEFAULT_WIDTH
})

// watch(maxWidth, () => {
//   if (maxPageWidth.value || rightDrawerWidth.value > maxWidth.value) {
//     rightDrawerWidth.value = maxWidth.value
//   } else if (rightDrawerWidth.value < DEFAULT_WIDTH) {
//     rightDrawerWidth.value = DEFAULT_WIDTH
//   }
// }, { immediate: true })

watch(
  maxWidth,
  (value) => {
    rightDrawerWidth.value = value
  },
  { immediate: true },
)
</script>

<style lang="scss">
.map-drawer-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  position: absolute;
  top: 16px;
  left: -72px;
  z-index: -1;
  transition: left 100ms ease-in-out;

  .v-btn__content {
    margin-inline-end: 32px;
  }
}
</style>
